const binding = { modules: {}, dataActions: {} };

    (binding.modules['csi'] = {
        c: () => require('partner/themes/csi/csi.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['active-image','b2b-requests-status','business-organization-list','buybox','cart-icon','cart','content-block','header','image-list','navigation-menu','order-details','order-history','order-template-list','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container','search','store-selector','text-block','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'csi',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'csi.theme.settings.json',
        md: 'src/themes/csi'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };